import { type UseModalState } from "@clipboard-health/ui-react";
import { Text } from "@clipboard-health/ui-react";
import { Dialog, DialogContent, LinearProgress, Stack } from "@mui/material";

interface DocumentUploadingModalProps {
  modalState: UseModalState;
}

export function DocumentUploadingModal(props: DocumentUploadingModalProps) {
  const { modalState } = props;
  const { modalIsOpen } = modalState;

  return (
    <Dialog fullWidth open={modalIsOpen} maxWidth="xs">
      <DialogContent
        sx={{
          minHeight: 340,
        }}
      >
        <Stack spacing={12} alignItems="center">
          <Text bold variant="h2">
            Uploading Document
          </Text>
          <Stack
            sx={{
              width: "100%",
              paddingX: 2,
              justifyContent: "center",
            }}
            spacing={5}
          >
            <LinearProgress sx={{ height: 5 }} />
            <Text variant="body1" align="center" sx={{ marginTop: 2 }}>
              This should take a few seconds
            </Text>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
