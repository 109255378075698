import { DocumentRequirementType } from "@src/appV2/Accounts/Documents/types";

export const ReplaceableDocumentRequirementTypes = new Set([
  DocumentRequirementType.COMPLETED,
  DocumentRequirementType.EXPIRED,
  DocumentRequirementType.REJECTED,
]);

export const ActionRequiredRequirementTypes = new Set([
  DocumentRequirementType.REJECTED,
  DocumentRequirementType.EXPIRED,
]);
