import { isDefined } from "@clipboard-health/util-ts";
import { RequirementWorkflowType } from "@src/appV2/Accounts/Documents/resources/requirements/constants";
import {
  type DocumentRequirementType,
  type HcpRequirement,
} from "@src/appV2/Accounts/Documents/types";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";

import { DocumentDetailsFooterType } from "../../types";
import { ActionRequiredRequirementTypes } from "../constants";

export interface GetDocumentDetailsFooterButtonPropertiesProps {
  selectedRequirement?: HcpRequirement;
  documentsDropOffFlowId: string;
  requirementWorkflowType?: RequirementWorkflowType;
  isHelloSignIntegrationEnabled: boolean;
  requirementType?: DocumentRequirementType;
  footerType:
    | DocumentDetailsFooterType.UPLOAD_DOCUMENT
    | DocumentDetailsFooterType.UPLOAD_REPLACEMENT;
  setDrawerIsVisible: (drawerIsVisible: boolean) => void;
  startHelloSignEmbeddedFlow: () => Promise<void>;
  buttonCtaText?: string;
}

export interface GetDocumentDetailsFooterButtonPropertiesResponse {
  buttonVariant: "contained" | "outlined";
  buttonText: string;
  onClick: () => void;
}

interface GetButtonTextProps {
  isHelloSignIntegrationEnabled: boolean;
  isHelloSignRequirement: boolean;
  documentFooterType: DocumentDetailsFooterType;
  buttonCtaText?: string;
}

function getButtonText(props: GetButtonTextProps): string {
  const {
    isHelloSignIntegrationEnabled,
    isHelloSignRequirement,
    documentFooterType,
    buttonCtaText,
  } = props;

  if (isHelloSignIntegrationEnabled) {
    if (isHelloSignRequirement) {
      return documentFooterType === DocumentDetailsFooterType.UPLOAD_DOCUMENT
        ? buttonCtaText ?? "Sign Document"
        : "Sign Replacement Document";
    }

    return documentFooterType === DocumentDetailsFooterType.UPLOAD_DOCUMENT
      ? buttonCtaText ?? "Upload Document"
      : "Upload Replacement Document";
  }

  return documentFooterType === DocumentDetailsFooterType.UPLOAD_DOCUMENT
    ? "Upload Document"
    : "Upload Replacement Document";
}

interface GetButtonVariantProps {
  documentFooterType: DocumentDetailsFooterType;
  requirementType?: DocumentRequirementType;
}

function getButtonVariant(props: GetButtonVariantProps): "contained" | "outlined" {
  const { documentFooterType, requirementType } = props;

  if (documentFooterType === DocumentDetailsFooterType.UPLOAD_DOCUMENT) {
    return "contained";
  }

  if (
    documentFooterType === DocumentDetailsFooterType.UPLOAD_REPLACEMENT &&
    isDefined(requirementType) &&
    ActionRequiredRequirementTypes.has(requirementType)
  ) {
    return "contained";
  }

  return "outlined";
}

export function getDocumentDetailsFooterButtonProperties(
  props: GetDocumentDetailsFooterButtonPropertiesProps
): GetDocumentDetailsFooterButtonPropertiesResponse {
  const {
    startHelloSignEmbeddedFlow,
    requirementWorkflowType,
    isHelloSignIntegrationEnabled,
    footerType: documentFooterType,
    setDrawerIsVisible,
    requirementType,
    buttonCtaText,
    selectedRequirement,
    documentsDropOffFlowId,
  } = props;

  const isHelloSignRequirement =
    requirementWorkflowType === RequirementWorkflowType.SIGN_WITH_HELLOSIGN;

  async function handleUploadDocumentOrSignHelloSignDocument() {
    logEvent(APP_V2_USER_EVENTS.DOCUMENTS_UPLOAD_FLOW_DOCUMENT_DETAILS_CTA_CLICKED, {
      requirementId: selectedRequirement?.reqId,
      requirementWorkflowType: selectedRequirement?.requirementWorkflowType,
      childRequirementFlow: requirementWorkflowType, // for composites, this will contain the child requirement workflow type
      documentsDropOffFlowId,
    });
    if (isHelloSignRequirement && isHelloSignIntegrationEnabled) {
      await startHelloSignEmbeddedFlow();
    } else {
      setDrawerIsVisible(true);
    }
  }

  const buttonText = getButtonText({
    isHelloSignIntegrationEnabled,
    isHelloSignRequirement,
    documentFooterType,
    buttonCtaText,
  });

  const buttonVariant = getButtonVariant({ documentFooterType, requirementType });

  return {
    buttonVariant,
    buttonText,
    onClick: handleUploadDocumentOrSignHelloSignDocument,
  };
}
