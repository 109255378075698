import { LoadingButton, Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogContent, DialogTitle, IconButton, Stack } from "@mui/material";

import { useDocumentDetailsContext } from "../../useDocumentDetails/context";
import { DocumentUploadCompletedModalButtonText } from "./constants";
import { type DocumentUploadCompletedModalProps } from "./types";

export function DocumentUploadCompletedModal(props: DocumentUploadCompletedModalProps) {
  const { modalState, isApproved, viewDocumentButtonText, onButtonCtaClick, isLoadingButtonCta } =
    props;
  const { modalIsOpen, closeModal } = modalState;
  const { isDocumentPreviewLoading } = useDocumentDetailsContext();

  return (
    <Dialog fullWidth open={modalIsOpen} maxWidth="xs" onClose={closeModal}>
      <DialogTitle>
        <IconButton
          aria-label="close"
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
            zIndex: 1,
          }}
          onClick={closeModal}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          minHeight: 340,
          display: "flex",
          alignItems: "center",
        }}
      >
        <Stack spacing={5} alignItems="center">
          <Stack
            sx={{
              width: "100%",
              paddingX: 2,
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
            spacing={2}
          >
            <Text bold variant="h2" sx={{ textAlign: "center" }}>
              {isApproved ? "Document Approved" : "Document Under Review"}
            </Text>
            <Text variant="h1" sx={{ fontSize: 88 }}>
              {isApproved ? "🎉" : "🔎"}
            </Text>
            <Text variant="body1" align="center" sx={{ marginTop: 2 }}>
              {isApproved
                ? "Your document has been successfully submitted and approved."
                : "Your document has been successfully submitted and is being reviewed."}
            </Text>
          </Stack>
          {isDefined(viewDocumentButtonText) && (
            <LoadingButton
              fullWidth
              isLoading={
                viewDocumentButtonText === DocumentUploadCompletedModalButtonText.VIEW_DOCUMENT
                  ? isDocumentPreviewLoading
                  : isLoadingButtonCta ?? false
              }
              variant="contained"
              sx={{ width: "75%" }}
              onClick={onButtonCtaClick}
            >
              {viewDocumentButtonText}
            </LoadingButton>
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
