import { type UseModalState } from "@clipboard-health/ui-react";
import { Text } from "@clipboard-health/ui-react";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Dialog, DialogContent, DialogTitle, IconButton, Stack } from "@mui/material";

import { type ErrorMessageVariants } from "./constants";

export interface DocumentUploadErrorModalProps {
  modalState: UseModalState;
  signAgain: boolean;
  errorMessage: ErrorMessageVariants;
}

export function DocumentUploadErrorModal(props: DocumentUploadErrorModalProps) {
  const { modalState, signAgain, errorMessage } = props;
  const { modalIsOpen, closeModal } = modalState;

  return (
    <Dialog fullWidth open={modalIsOpen} maxWidth="xs" onClose={closeModal}>
      <DialogTitle>
        <IconButton
          aria-label="close"
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
            zIndex: 1,
          }}
          onClick={closeModal}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          minHeight: 340,
          display: "flex",
          alignItems: "center",
        }}
      >
        <Stack spacing={5} alignItems="center">
          <Stack
            sx={{
              width: "100%",
              paddingX: 2,
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
            spacing={2}
          >
            <Text bold align="center" variant="h2">
              Something went wrong
            </Text>

            <Text variant="body1" align="center" sx={{ marginTop: 2 }}>
              {errorMessage}
            </Text>
          </Stack>
          {signAgain && (
            <Button fullWidth sx={{ width: "50%" }} variant="contained" onClick={closeModal}>
              Sign Again
            </Button>
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
