import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { StripeIdentityVerificationFlowStatus } from "@src/appV2/Stripe";
import constate from "constate";

import { useDocumentUploadContext } from "../../../hooks/useDocumentUpload";
import { useDocumentDetailsContext } from "../../../useDocumentDetails";
import { getDocumentDetailsFooterType } from "./utils/getDocumentDetailsFooterType";

function useDocumentDetailsFooter() {
  const {
    requirementId,
    personalIdSubtype,
    selectedRequirement,
    requirementType,
    isStripeVerificationFlow,
    refreshQueryAndGoBackToDocumentsPage,
    deleteDocumentModalState,
    isStripeReady,
    startStripeVerificationFlow,
    startHelloSignEmbeddedFlow,
    documentsDropOffFlowId,
  } = useDocumentDetailsContext();
  const {
    selectedFiles,
    fileSelectionType,
    uploadAndClearSelectedFiles,
    takeCameraPhoto,
    selectGalleryPhoto,
    selectFile,
  } = useDocumentUploadContext();

  return {
    deleteDocument: deleteDocumentModalState.openModal,
    startStripeFlow: async () => {
      if (isStripeReady) {
        const { stripeVerificationStatus } = await startStripeVerificationFlow({
          personalIdSubtype,
          selectedRequirement,
        });
        if (stripeVerificationStatus === StripeIdentityVerificationFlowStatus.COMPLETED) {
          await refreshQueryAndGoBackToDocumentsPage();
        }
      }
    },
    finishAndUpload: async () => {
      await uploadAndClearSelectedFiles({
        requirementId,
        personalIdSubtype,
        selectedRequirement,
      });
      logEvent(APP_V2_USER_EVENTS.DOCUMENTS_UPLOAD_FLOW_DOCUMENT_DETAILS_SUBMISSION_FINISHED, {
        requirementId,
        requirementWorkflowType: selectedRequirement?.requirementWorkflowType,
        documentsDropOffFlowId,
      });
    },
    footerType: getDocumentDetailsFooterType({
      requirementType,
      selectedFiles,
      fileSelectionType,
      isStripeVerificationFlow,
    }),
    takeCameraPhoto,
    selectGalleryPhoto,
    selectFile,
    startHelloSignEmbeddedFlow,
  };
}

export const [DocumentDetailsFooterContextProvider, useDocumentDetailsFooterContext] =
  constate(useDocumentDetailsFooter);
