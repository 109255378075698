import { isDefined } from "@clipboard-health/util-ts";
import { RequirementWorkflowType } from "@src/appV2/Accounts/Documents/resources/requirements/constants";
import { type HcpRequirement } from "@src/appV2/Accounts/Documents/types";

interface GetCompositeChildrenPropertiesProps {
  selectedRequirement?: HcpRequirement;
}

export function getCompositeChildrenProperties(props: GetCompositeChildrenPropertiesProps) {
  const { selectedRequirement } = props;

  if (selectedRequirement?.requirementWorkflowType !== RequirementWorkflowType.COMPOSITE) {
    return undefined;
  }

  const compositeChildOne = selectedRequirement?.compositeConfig?.requirements[0];
  const compositeChildTwo = selectedRequirement?.compositeConfig?.requirements[1];

  const compositeChildOneRequirement = selectedRequirement?.childRequirements?.find(
    ({ _id }) => _id === compositeChildOne?.requirementId
  );
  const compositeChildTwoRequirement = selectedRequirement?.childRequirements?.find(
    ({ _id }) => _id === compositeChildTwo?.requirementId
  );

  if (
    !isDefined(compositeChildOneRequirement) ||
    !isDefined(compositeChildTwoRequirement) ||
    !isDefined(compositeChildOne) ||
    !isDefined(compositeChildTwo)
  ) {
    return undefined;
  }

  return {
    compositeChildOne: {
      requirementWorkflowType: compositeChildOneRequirement.requirementWorkflowType,
      ctaText: compositeChildOne.ctaText,
    },
    compositeChildTwo: {
      requirementWorkflowType: compositeChildTwoRequirement.requirementWorkflowType,
      ctaText: compositeChildTwo.ctaText,
    },
  };
}
