export enum DocumentUploadCompletedModalButtonText {
  VIEW_DOCUMENT = "View Document",
  VIEW_NEXT_DOCUMENT = "View Next Document",
  OKAY = "Okay",
}

export enum ErrorMessageVariants {
  UNEXPECTED_ERROR = "We encountered an unexpected error. Please try signing the document again.",
  HELLO_SIGN_DOWN = "Our signing platform seems to be down. Please try again in 5-10 minutes.",
  ALREADY_SIGNED = "This document has already been signed and will appear as pending in a few minutes. Please wait a few minutes while our system updates.",
}
